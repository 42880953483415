import React, { useState } from 'react';
import './App.css';
import { FaTelegramPlane, FaTwitter, FaYoutube, FaDollarSign, FaCheck } from 'react-icons/fa';

function TasksPage() {
  // State for button statuses
  const [taskStatuses, setTaskStatuses] = useState({
    weeklyCheckin: 'Claim',
    xReposts: 'Start',
    telegramReposts: 'Start',
    youtubeComment: 'Start',
    youtubeVideo: 'Start', // New task for watching YouTube video
    xComment: 'Start',
    contribute1TON: 'Start',
    contribute10TON: 'Start',
    contribute100TON: 'Start',
  });

  // Generic handler for task buttons
  const handleButtonClick = (taskKey, action) => {
    setTaskStatuses((prev) => {
      const newState = { ...prev };

      if (action === 'Start') {
        // Simulate task action (e.g., opening a link, alert, etc.)
        alert(`Perform the task: ${taskKey}`);
        newState[taskKey] = 'Claim';
      } else if (action === 'Claim') {
        // Simulate validation logic
        const isTaskCompleted = true; // Replace with actual validation logic
        if (isTaskCompleted) {
          newState[taskKey] = 'Claimed';
        } else {
          alert(`Please complete the task: ${taskKey}`);
        }
      }

      return newState;
    });
  };

  return (
    <div className="bg-black text-white min-h-screen flex flex-col">
      {/* Header */}
      <header className="p-6 bg-black">
        <h1 className="text-2xl font-bold text-center">Make TASKS to earn more WHALES</h1>
      </header>

      <div className="flex-grow overflow-y-auto p-6">
        {/* SPECIAL TASKS Section */}
        <section className="mb-8">
          <h2 className="text-xl font-bold">SPECIAL TASKS</h2>
          <ul className="mt-4 space-y-4">
            <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
              <div className="flex items-center space-x-4">
                <FaCheck size={24} />
                <div>
                  <p>Weekly Check-in</p>
                  <p className="text-sm text-gray-400">+1,000 WHALES</p>
                </div>
              </div>
              <button
                onClick={() =>
                  handleButtonClick('weeklyCheckin', taskStatuses.weeklyCheckin)
                }
                className={`px-4 py-2 rounded ${
                  taskStatuses.weeklyCheckin === 'Claimed'
                    ? 'bg-red-500 text-white'
                    : 'bg-gray-600'
                }`}
                disabled={taskStatuses.weeklyCheckin === 'Claimed'}
              >
                {taskStatuses.weeklyCheckin}
              </button>
            </li>
          </ul>
        </section>

        {/* MARKETING TASKS Section */}
        <section className="mb-8">
          <h2 className="text-xl font-bold">MARKETING TASKS</h2>
          <ul className="mt-4 space-y-4">
            {[
              { key: 'xReposts', label: 'Make X Reposts', reward: '+1,000 WHALES', icon: FaTwitter },
              { key: 'telegramReposts', label: 'Make Telegram Reposts', reward: '+1,000 WHALES', icon: FaTelegramPlane },
              { key: 'youtubeComment', label: 'Make YouTube Comment', reward: '+1,000 WHALES', icon: FaYoutube },
              { key: 'youtubeVideo', label: 'Watch YouTube Video', reward: '+1,000 WHALES', icon: FaYoutube }, // New task
              { key: 'xComment', label: 'Make X Comment', reward: '+1,000 WHALES', icon: FaTwitter },
            ].map(({ key, label, reward, icon: Icon }) => (
              <li
                key={key}
                className="flex justify-between items-center bg-gray-800 p-4 rounded-lg"
              >
                <div className="flex items-center space-x-4">
                  <Icon size={24} />
                  <div>
                    <p>{label}</p>
                    <p className="text-sm text-gray-400">{reward}</p>
                  </div>
                </div>
                <button
                  onClick={() => handleButtonClick(key, taskStatuses[key])}
                  className={`px-4 py-2 rounded ${
                    taskStatuses[key] === 'Claimed'
                      ? 'bg-red-500 text-white'
                      : 'bg-gray-600'
                  }`}
                  disabled={taskStatuses[key] === 'Claimed'}
                >
                  {taskStatuses[key]}
                </button>
              </li>
            ))}
          </ul>
        </section>

        {/* CONTRIBUTION TASKS Section */}
        <section className="mb-8">
          <h2 className="text-xl font-bold">CONTRIBUTION TASKS</h2>
          <ul className="mt-4 space-y-4">
            {[
              { key: 'contribute1TON', label: 'Contribute 1 TON', reward: '+10,000 WHALES', icon: FaDollarSign },
              { key: 'contribute10TON', label: 'Contribute 10 TON', reward: '+100,000 WHALES', icon: FaDollarSign },
              { key: 'contribute100TON', label: 'Contribute 100 TON', reward: '+1,000,000 WHALES', icon: FaDollarSign },
            ].map(({ key, label, reward, icon: Icon }) => (
              <li
                key={key}
                className="flex justify-between items-center bg-gray-800 p-4 rounded-lg"
              >
                <div className="flex items-center space-x-4">
                  <Icon size={24} />
                  <div>
                    <p>{label}</p>
                    <p className="text-sm text-gray-400">{reward}</p>
                  </div>
                </div>
                <button
                  onClick={() => handleButtonClick(key, taskStatuses[key])}
                  className={`px-4 py-2 rounded ${
                    taskStatuses[key] === 'Claimed'
                      ? 'bg-red-500 text-white'
                      : 'bg-gray-600'
                  }`}
                  disabled={taskStatuses[key] === 'Claimed'}
                >
                  {taskStatuses[key]}
                </button>
              </li>
            ))}
          </ul>
        </section>
        {/* Spacer for Bottom Padding */}
      <div className="h-16"></div>
      </div>
    </div>
  );
}

export default TasksPage;