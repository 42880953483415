import React from 'react';
import './App.css';

function FriendsPage() {
  return (
    <div className="bg-black text-white min-h-screen flex flex-col">
      {/* Header */}
      <header className="p-6 bg-black">
        <h1 className="text-2xl font-bold text-center">Invite friends & get more WHALES</h1>
      </header>

      <div className="flex-grow overflow-y-auto p-6">
        {/* Invite Section */}
        <section className="mb-8 bg-gray-800 p-4 rounded-lg">
          <h2 className="text-lg font-bold">Invite a friend</h2>
          <p className="text-sm text-gray-400">Get +10,000 WHALES & 5% of your friend's earnings</p>
          <div className="flex justify-between items-center mt-4">
            <button className="bg-yellow-500 px-6 py-2 rounded-lg font-bold text-black">Invite friend</button>
            <button className="bg-gray-600 px-4 py-2 rounded flex items-center">
              <span className="mr-2">Copy</span>
            </button>
          </div>
        </section>

        {/* Friends Info */}
        <div className="flex justify-between text-gray-400 mb-4">
          <p>0 Friends</p>
          <p>0 WHALES</p>
        </div>

        {/* Friends List */}
        <section className="bg-gray-800 p-4 rounded-lg">
          <p className="text-center text-gray-400">Here is no friends yet</p>
        </section>
      </div>
    </div>
  );
}

export default FriendsPage;