import React from 'react';
import './App.css';

function LeadersPage() {
  return (
    <div className="bg-black text-white min-h-screen flex flex-col">
      {/* Header */}
      <header className="p-6 bg-black">
        <h1 className="text-2xl font-bold text-center">Leaderboard</h1>
        <p className="text-center text-gray-400">Ranking info</p>
      </header>

      {/* Top Leaderboard */}
      <section className="p-6">
        <div className="flex justify-around items-end">
          <div className="flex flex-col items-center">
            <p className="text-xl font-bold text-gray-300">2</p>
            <p className="text-lg font-bold text-white">227.427 M</p>
            <p className="text-sm text-gray-400">MousaMj</p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-3xl font-bold text-yellow-500">1</p>
            <p className="text-xl font-bold text-white">243.596 M</p>
            <p className="text-sm text-gray-400">MEMES</p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-xl font-bold text-gray-300">3</p>
            <p className="text-lg font-bold text-white">10.219 M</p>
            <p className="text-sm text-gray-400">Hessam</p>
          </div>
        </div>
      </section>

      {/* User Rank */}
      <section className="p-6">
        <div className="bg-gray-800 p-4 rounded-lg">
          <div className="flex justify-between items-center">
            <p className="text-white font-bold">VETER_WHALES</p>
            <p className="text-gray-400">#390</p>
          </div>
          <p className="text-yellow-500 font-bold">11,000</p>
        </div>
      </section>

      {/* Leaderboard List */}
      <section className="p-6">
        <ul className="space-y-4">
          <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
            <p className="text-white font-bold">MEMES</p>
            <p className="text-yellow-500 font-bold">243.596 M</p>
            <p className="text-sm text-green-500">+500 TON reward</p>
          </li>
          <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
            <p className="text-white font-bold">MousaMj</p>
            <p className="text-yellow-500 font-bold">227.427 M</p>
            <p className="text-sm text-green-500">+350 TON reward</p>
          </li>
          <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
            <p className="text-white font-bold">Hessam</p>
            <p className="text-yellow-500 font-bold">10.219 M</p>
            <p className="text-sm text-green-500">+250 TON reward</p>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default LeadersPage;