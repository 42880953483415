import React, { useState } from 'react';
import './App.css';
import {
  FaHome,
  FaDollarSign,
  FaUsers,
  FaTrophy,
  FaTwitter,
  FaUserFriends,
  FaTelegramPlane,
  FaMedal,
  FaYoutube,
} from 'react-icons/fa';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import TasksPage from './TasksPage'; // Import your Tasks page component
import whaleLogo from './assets/whale-logo.png';
import FriendsPage from './FriendsPage'; // Adjust the path if FriendsPage is in a different folder
import LeadersPage from './LeadersPage'; // Import LeadersPage component

function App() {
  // Define your links here
  const telegramLink = 'https://t.me/whalescoinorg'; // Replace with your Telegram channel link
  const twitterLink = 'https://x.com/WHALESCOINORG'; // Replace with your Twitter/X profile link

  // States for each task's button status
  const [telegramTaskStatus, setTelegramTaskStatus] = useState('Start');
  const [twitterTaskStatus, setTwitterTaskStatus] = useState('Start');
  const [tonTaskStatus, setTonTaskStatus] = useState('Start');
  const [youtubeTaskStatus, setYoutubeTaskStatus] = useState('Start');

  // States for Invite Friends section claim buttons
  const [invite10FriendsStatus, setInvite10FriendsStatus] = useState('Claim');
  const [invite25FriendsStatus, setInvite25FriendsStatus] = useState('Claim');
  const [invite50FriendsStatus, setInvite50FriendsStatus] = useState('Claim');

  // Handlers for each task button
  const handleTelegramButtonClick = () => {
    if (telegramTaskStatus === 'Start') {
      window.open(telegramLink, '_blank');
      setTelegramTaskStatus('Claim');
    } else if (telegramTaskStatus === 'Claim') {
      const isSubscribed = true; // Replace with actual subscription check logic
      if (isSubscribed) {
        setTelegramTaskStatus('Claimed');
      } else {
        alert('Please subscribe to the channel to claim your reward.');
      }
    }
  };

  const handleTwitterButtonClick = () => {
    if (twitterTaskStatus === 'Start') {
      window.open(twitterLink, '_blank');
      setTwitterTaskStatus('Claim');
    } else if (twitterTaskStatus === 'Claim') {
      const isFollowing = true; // Replace with actual follow check logic
      if (isFollowing) {
        setTwitterTaskStatus('Claimed');
      } else {
        alert('Please follow on X to claim your reward.');
      }
    }
  };

  const handleTonButtonClick = () => {
    if (tonTaskStatus === 'Start') {
      alert('Please make a 1 TON Contribution to continue.');
      setTonTaskStatus('Claim');
    } else if (tonTaskStatus === 'Claim') {
      const isContributionMade = true; // Replace with actual contribution check logic
      if (isContributionMade) {
        setTonTaskStatus('Claimed');
      } else {
        alert('Please complete the task to claim your reward.');
      }
    }
  };

  const handleYoutubeButtonClick = () => {
    if (youtubeTaskStatus === 'Start') {
      alert('Watch the WhaleCoins Intro video to continue.');
      setYoutubeTaskStatus('Claim');
    } else if (youtubeTaskStatus === 'Claim') {
      const isWatched = true; // Replace with actual video check logic
      if (isWatched) {
        setYoutubeTaskStatus('Claimed');
      } else {
        alert('Please watch the video to claim your reward.');
      }
    }
  };

  // Handlers for Invite Friends buttons
  const handleInvite10FriendsClick = () => {
    if (invite10FriendsStatus === 'Claim') {
      const hasInvited = true; // Replace with actual invite check logic
      if (hasInvited) {
        setInvite10FriendsStatus('Claimed');
      } else {
        alert('Please complete the invite task to claim your reward.');
      }
    }
  };

  const handleInvite25FriendsClick = () => {
    if (invite25FriendsStatus === 'Claim') {
      const hasInvited = true; // Replace with actual invite check logic
      if (hasInvited) {
        setInvite25FriendsStatus('Claimed');
      } else {
        alert('Please complete the invite task to claim your reward.');
      }
    }
  };

  const handleInvite50FriendsClick = () => {
    if (invite50FriendsStatus === 'Claim') {
      const hasInvited = true; // Replace with actual invite check logic
      if (hasInvited) {
        setInvite50FriendsStatus('Claimed');
      } else {
        alert('Please complete the invite task to claim your reward.');
      }
    }
  };

  const MainPage = () => (
    <div className="flex-grow overflow-y-auto">
      {/* Header Section */}
      <header className="p-6 bg-black">
        <img src={whaleLogo} alt="WhaleCoins Logo" className="w-50 h-50" />
        <div className="mt-4 flex flex-col items-center">
          <p className="text-4xl font-bold text-white">150,000</p>
          <p className="text-xl text-white">WHALES</p>
          <button className="mt-4 px-6 py-2 bg-yellow-500 text-black font-bold rounded-lg shadow-lg">
            Withdraw to Wallet
          </button>
        </div>
      </header>

      {/* Tasks Section */}
      <section className="p-6">
        <h2 className="text-xl font-bold">Complete Tasks & Earn</h2>
        <ul className="mt-4 space-y-4">
          {/* Telegram Task */}
          <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
            <div className="flex items-center space-x-4">
              <FaTelegramPlane size={24} />
              <div>
                <p>Join Telegram Channel</p>
                <p className="text-sm text-gray-400">+5,000 WHALES</p>
              </div>
            </div>
            <button
              onClick={handleTelegramButtonClick}
              className={`px-3 py-2 rounded ${
                telegramTaskStatus === 'Claimed' ? 'bg-red-500 text-white' : 'bg-gray-600'
              }`}
              disabled={telegramTaskStatus === 'Claimed'}
            >
              {telegramTaskStatus}
            </button>
          </li>

          {/* Twitter Task */}
          <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
            <div className="flex items-center space-x-4">
              <FaTwitter size={24} />
              <div>
                <p>Follow on X</p>
                <p className="text-sm text-gray-400">+5,000 WHALES</p>
              </div>
            </div>
            <button
              onClick={handleTwitterButtonClick}
              className={`px-3 py-2 rounded ${
                twitterTaskStatus === 'Claimed' ? 'bg-red-500 text-white' : 'bg-gray-600'
              }`}
              disabled={twitterTaskStatus === 'Claimed'}
            >
              {twitterTaskStatus}
            </button>
          </li>

          {/* TON Contribution Task */}
          <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
            <div className="flex items-center space-x-4">
              <FaDollarSign size={24} />
              <div>
                <p>Make 1 TON Contribution</p>
                <p className="text-sm text-gray-400">+10,000 WHALES</p>
              </div>
            </div>
            <button
              onClick={handleTonButtonClick}
              className={`px-3 py-2 rounded ${
                tonTaskStatus === 'Claimed' ? 'bg-red-500 text-white' : 'bg-gray-600'
              }`}
              disabled={tonTaskStatus === 'Claimed'}
            >
              {tonTaskStatus}
            </button>
          </li>

          {/* YouTube Task */}
          <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
            <div className="flex items-center space-x-4">
              <FaYoutube size={24} />
              <div>
                <p>Watch WhaleCoins Intro</p>
                <p className="text-sm text-gray-400">+10,000 WHALES</p>
              </div>
            </div>
            <button
              onClick={handleYoutubeButtonClick}
              className={`px-3 py-2 rounded ${
                youtubeTaskStatus === 'Claimed' ? 'bg-red-500 text-white' : 'bg-gray-600'
              }`}
              disabled={youtubeTaskStatus === 'Claimed'}
            >
              {youtubeTaskStatus}
            </button>
          </li>
        </ul>
      </section>
      {/* Invite Friends Section */}
      <section className="p-6">
            <h2 className="text-xl font-bold">Invite Friends</h2>
            <ul className="mt-4 space-y-4">
              <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
                <div className="flex items-center space-x-4">
                  <FaUserFriends size={24} />
                  <div>
                    <p>Invite 10 Friends</p>
                    <p className="text-sm text-gray-400">+20,000 WHALES</p>
                  </div>
                </div>
                <button
                  onClick={handleInvite10FriendsClick}
                  className={`px-3 py-2 rounded ${
                    invite10FriendsStatus === 'Claimed' ? 'bg-red-500 text-white' : 'bg-gray-600'
                  }`}
                  disabled={invite10FriendsStatus === 'Claimed'}
                >
                  {invite10FriendsStatus}
                </button>
              </li>

              <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
                <div className="flex items-center space-x-4">
                  <FaUserFriends size={24} />
                  <div>
                    <p>Invite 25 Friends</p>
                    <p className="text-sm text-gray-400">+50,000 WHALES</p>
                  </div>
                </div>
                <button
                  onClick={handleInvite25FriendsClick}
                  className={`px-3 py-2 rounded ${
                    invite25FriendsStatus === 'Claimed' ? 'bg-red-500 text-white' : 'bg-gray-600'
                  }`}
                  disabled={invite25FriendsStatus === 'Claimed'}
                >
                  {invite25FriendsStatus}
                </button>
              </li>

              <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
                <div className="flex items-center space-x-4">
                  <FaUserFriends size={24} />
                  <div>
                    <p>Invite 50 Friends</p>
                    <p className="text-sm text-gray-400">+100,000 WHALES</p>
                  </div>
                </div>
                <button
                  onClick={handleInvite50FriendsClick}
                  className={`px-3 py-2 rounded ${
                    invite50FriendsStatus === 'Claimed' ? 'bg-red-500 text-white' : 'bg-gray-600'
                  }`}
                  disabled={invite50FriendsStatus === 'Claimed'}
                >
                  {invite50FriendsStatus}
                </button>
              </li>
            </ul>
          </section>

      {/* Rewards Section */}
      <section className="p-6">
        <h2 className="text-xl font-bold">Your Rewards</h2>
        <ul className="mt-4 space-y-4">
          <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
            <div className="flex items-center space-x-4">
              <FaMedal size={24} />
              <p>Check-in Rewards</p>
            </div>
            <p className="text-white">+50,000 Whales</p>
          </li>
          <li className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
            <div className="flex items-center space-x-4">
              <FaMedal size={24} />
              <p>Tasks Rewards</p>
            </div>
            <p className="text-white">+100,000 Whales</p>
          </li>
        </ul>
      </section>
      {/* Spacer for Bottom Padding */}
      <div className="h-16"></div>
    </div>
  );

  return (
    <Router>
      <div className="bg-black text-white min-h-screen flex flex-col">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/tasks" element={<TasksPage />} />
          <Route path="/friends" element={<FriendsPage />} />
          <Route path="/leaders" element={<LeadersPage />} />
        </Routes>
        <nav className="bg-gray-900 p-4 fixed bottom-0 w-full flex justify-around text-white">
          <Link to="/" className="flex flex-col items-center">
            <FaHome size={24} />
            <p className="text-sm">Home</p>
          </Link>
          <Link to="/tasks" className="flex flex-col items-center">
            <FaDollarSign size={24} />
            <p className="text-sm">Tasks</p>
          </Link>
          <Link to="/friends" className="flex flex-col items-center">
            <FaUsers size={24} />
            <p className="text-sm">Friends</p>
          </Link>
          <Link to="/leaders" className="flex flex-col items-center">
            <FaTrophy size={24} />
            <p className="text-sm">Leaders</p>
          </Link>
        </nav>
      </div>
    </Router>
  );
}

export default App;